import axios from "axios";
import Config from "../utils/Config";

// post with Object
export const callPostWithValueRestApis = async (URL, Obj, type) => {
  let Conn = navigator.onLine;
  if (!Conn) {
    throw "No internet connection.";
  } else {
    let url = process.env.REACT_APP_API_URL + URL;
    let headers = {
      token: localStorage.getItem("accessToken"),
    };

    // Check if accessToken exists and add Content-Type header accordingly
    if (type === "img") {
      headers["Content-Type"] = "multipart/form-data";
    }
    return axios
      .post(url, Obj, {
        headers: headers,
      })
      .then((res) => {
        return res.data ? res.data : res;
      })
      .catch((err) => {
        handleErrors(err);
      });
  }
};

export const callPutWithValueRestApis = async (URL, Obj, accessToken) => {
  let Conn = navigator.onLine;
  if (!Conn) {
    throw "No internet connection.";
  } else {
    let url = process.env.REACT_APP_API_URL + URL;
    return axios
      .put(url, Obj, {
        headers: {
          token: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        return res.data ? res.data : res;
      })
      .catch((err) => {
        handleErrors(err);
      });
  }
};

export const callDeleteWithValueRestApis = async (URL, accessToken) => {
  let Conn = navigator.onLine;
  if (!Conn) {
    throw "No internet connection.";
  } else {
    let url = process.env.REACT_APP_API_URL + URL;
    return axios
      .delete(url, {
        headers: {
          token: localStorage.getItem("accessToken"),
        },
      })
      .then((res) => {
        return res.data ? res.data : res;
      })
      .catch((err) => {
        handleErrors(err);
      });
  }
};

// Post get api
export const callGetRestApis = async (URL, accessToken) => {
  let Conn = navigator.onLine;
  if (!Conn) {
    throw "No internet connection.";
  } else {
    let url = process.env.REACT_APP_API_URL + URL;
    return axios(url, {
      headers: {
        token: localStorage.getItem("accessToken"),
      },
    })
      .then((res) => {
        return res.data ? res.data : res;
      })
      .catch((err) => {
        handleErrors(err);
      });
  }
};

const handleErrors = (error) => {
  if (!error.response) {
    // network error
    throw error.message
      ? error.message
      : "Please check your network connection.";
  } else {
    // http status code
    const code = error?.response?.status;
    // response data
    const { error: errorMsg = {} } = error.response.data;

    if (code === 400) {
      throw "Please Provide valid credential.";
    } else if (code === 401) {
      localStorage.clear();
      window.location.href = "/";
    } else if (error.response.status === 404) {
      throw errorMsg.message ? errorMsg.message : error.message;
    } else if (error && error.response && error.response.status === 500) {
      throw errorMsg.message ? errorMsg.message : error.message;
    } else {
      throw "Oops server error occurred";
    }
  }
};
