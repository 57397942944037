import React from "react";
import "./App.css";
import MainRoutes from "./components/Routes/MainRoutes";

function App() {
  return (
    <>
      <MainRoutes />
    </>
  );
}

export default App;
