import { createSlice } from "@reduxjs/toolkit";

// define initial state for this slice
const initialState = {
  openModal: {
    aboutMe: false,
    experience: false,
    technicalSkills: false,
    project: false,
  },
};

// define the slice, with reducers
export const reactModal = createSlice({
  name: "reactModal",
  initialState,
  reducers: {
    setOpenModal: (state, action) => {
      state.openModal[action.payload] = true;
    },
    setCloseModal: (state, action) => {
      state.openModal[action.payload] = false;
    },
  },
});

// export actions
export const { setOpenModal, setCloseModal } = reactModal.actions;

//selectors for extracting from state
export const selectOpenModal = (state) => state.reactModal;
