import { configureStore } from "@reduxjs/toolkit";
import { reactModal } from "./ReactModal/ReactModal";
import { form } from "./Form/form";

export default configureStore({
  reducer: {
    reactModal: reactModal.reducer,
    form: form.reducer,
  },
});

// Gives global access to actions of different slices
window.actions = {
  reactModal: reactModal.actions,
  form: form.actions,
};
