import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Tooltip,
  TablePagination,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
} from "@mui/material";
import Button from "@mui/material/Button";
import { Edit, Delete, GetApp, ContentCopy } from "@mui/icons-material";
import { Link, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";

const ReactTable = (props) => {
  const dispatch = useDispatch();
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [listType, setListType] = useState("All");

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
    props.handlePageChange(props.type, newPage, rowsPerPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
    props.handleChangeRowsPerPage(props.type, parseInt(event.target.value, 10));
  };

  const handleCopy = (data) => {
    props.handleCloseModal(props.type, data, props.selectedCardIndex);
  };

  const handleSearch = (value) => {
    setSearch(value);
    setRowsPerPage(10);
    setPage(0);
    props.handleSearchInput(props.type, value, listType);
  };

  const handleChangeType = (value) => {
    setRowsPerPage(10);
    setPage(0);
    setListType(value);
    props.handleTypeChange(props.type, value, search);
  };

  return (
    <div>
      <div style={{ display: "flex" }}>
        <p>
          Select Existing <b>{props.title}</b>
        </p>
        {props.search && (
          <div className="search-container" style={{ marginLeft: "20px" }}>
            <TextField
              value={search}
              placeholder="Search"
              className="search-input"
              InputProps={{
                disableUnderline: true,
                classes: {
                  notchedOutline: "no-border", // Add this class to remove the outline border
                },
              }}
              style={{
                height: "36px",
                width: "250px",
                borderRadius: "20px",
                border: "2px solid rgb(36 17 159 / 83%)",
                padding: "8px 15px",
                fontSize: "14px",
                marginRight: "10px",
              }}
              onChange={(e) => {
                handleSearch(e.target.value);
              }}
            />
          </div>
        )}
        <FormControl style={{ width: "200px", marginLeft: "20px" }}>
          <InputLabel id="profile-type-label">Select Favourites</InputLabel>
          <Select
            labelId="profile-type-label"
            id="profile-type-select"
            value={listType}
            onChange={(e) => handleChangeType(e.target.value)}
            style={{ height: "36px", marginRight: "10px" }}
          >
            <MenuItem value={"All"}>All</MenuItem>
            <MenuItem value={"Favourites"}>Favourites</MenuItem>
          </Select>
        </FormControl>
      </div>
      <TableContainer
        component={Paper}
        className="table-container modal-table-container"
        style={{ height: "420px" }}
      >
        <Table>
          <TableHead>
            <TableRow>
              {props.columns.map((col) => {
                return (
                  <TableCell className="table-header">{col.Header}</TableCell>
                );
              })}
            </TableRow>
          </TableHead>
          <TableBody>
            {props.data.length ? (
              props.data.map((item, index) => {
                return (
                  <TableRow key={index}>
                    {props.columns.map((col) => {
                      if (col.accessor === "useButton") {
                        return (
                          <TableCell>
                            <Button
                              className="action-delete"
                              onClick={() => {
                                handleCopy(item);
                              }}
                            >
                              Use
                            </Button>
                          </TableCell>
                        );
                      } else if (
                        col.accessor === "roles_responsibility" ||
                        col.accessor === "description"
                      ) {
                        return (
                          <TableCell>
                            <div
                              dangerouslySetInnerHTML={{
                                __html: item[col.accessor],
                              }}
                            />
                          </TableCell>
                        );
                      } else {
                        return <TableCell>{item[col.accessor]}</TableCell>;
                      }
                    })}
                  </TableRow>
                );
              })
            ) : (
              <TableRow>
                <TableCell
                  colSpan={props.columns.length}
                  style={{ textAlign: "center" }}
                >
                  No Data Found
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={props.count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default ReactTable;
