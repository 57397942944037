export const aboutMeModalColumns = [
  {
    Header: "About Me",
    accessor: "summary",
  },
  {
    Header: "Action",
    accessor: "useButton",
  },
];

export const experienceModalColumns = [
  {
    Header: "Responsibilities",
    accessor: "description",
  },
  {
    Header: "Action",
    accessor: "useButton",
  },
];

export const technicalSkillsModalColumns = [
  {
    Header: "Skill Title",
    accessor: "skill_title",
  },
  {
    Header: "Skill",
    accessor: "skills",
  },
  {
    Header: "Action",
    accessor: "useButton",
  },
];

export const projectModalColumns = [
  {
    Header: "Project Name",
    accessor: "project_name",
  },
  {
    Header: "Project Details",
    accessor: "project_details",
  },
  {
    Header: "Project Technologies",
    accessor: "project_technologies",
  },
  {
    Header: "Roles & Responsibilities",
    accessor: "roles_responsibility",
  },
  {
    Header: "Action",
    accessor: "useButton",
  },
];
