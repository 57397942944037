import React from "react";
import AddResume from "./AddResume";
const EditResume = () => {
  const url = window.location.pathname;
  const pathname = url.split("/");
  const id = pathname[2];

  return <AddResume id={id} />;
};

export default EditResume;
