import React, { useState, useEffect } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  TextField,
  Tooltip,
  TablePagination,
  Menu,
  MenuItem,
  FormControl,
  InputLabel,
  Select,
  Chip,
  Avatar,
  InputAdornment,
} from "@mui/material";
import Button from "@mui/material/Button";
import {
  Edit,
  Delete,
  GetApp,
  ContentCopy,
  Star,
  StarBorder,
  Search,
} from "@mui/icons-material";
import "./Dashboard.css"; // Import CSS file
import { Link, useNavigate } from "react-router-dom";
import Swal from "sweetalert2";
import {
  callDeleteWithValueRestApis,
  callGetRestApis,
  callPostWithValueRestApis,
} from "../../Services/Api";
import Config from "../../utils/Config";
import Navbar from "../Navbar.jsx/Navbar";
import { getAccessToken } from "../../utils/utils";
import { getAllUserProfiles } from "../../redux/dashboard/dashboardAPI";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import DeleteOutlineOutlinedIcon from "@mui/icons-material/DeleteOutlineOutlined";
import GroupAddOutlinedIcon from "@mui/icons-material/GroupAddOutlined";
import barIcon from "./total-bar.png";

const Dashboard = () => {
  const navigate = useNavigate();
  const [userProfiles, setUserProfiles] = useState([]);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [search, setSearch] = useState("");
  const [count, setTotalCount] = useState(0);
  const [anchorEl, setAnchorEl] = useState(null);
  const [downloadCvId, setDownloadCvId] = useState(null);
  const [profileType, setProfileType] = useState("All");
  const [totalRecords, setTotalRecords] = useState({
    total: 0,
    download: 0,
    deleted: 0,
  });
  const accessToken = getAccessToken();

  useEffect(() => {
    getAllProfiles();
    getTotalRecords();
  }, [page, search, rowsPerPage, profileType]);

  const getTotalRecords = async () => {
    const data = await callGetRestApis(Config.TOTALRECORDS, accessToken);
    let records = data?.data;

    setTotalRecords((prevState) => ({
      ...prevState,
      total: records?.totalcv,
      download: records?.download,
      deleted: records?.delete,
    }));
  };

  const getAllProfiles = async () => {
    let queryParams = `?name=${search}&page=${
      page + 1
    }&pagesize=${rowsPerPage}`;
    let queryParamsType = `?name=${search}&page=${
      page + 1
    }&pagesize=${rowsPerPage}&contractor_employess=${
      profileType === "Contractor" ? 1 : profileType === "Employee" ? 0 : ""
    }`;
    const { status, data } = await getAllUserProfiles(
      profileType === "Contractor" || profileType === "Employee"
        ? queryParamsType
        : queryParams,
      accessToken
    );
    if (status === 200) {
      let newData = data?.list || [];
      let totalUsers = data?.total_count;
      setUserProfiles(newData);
      setTotalCount(totalUsers);
    }
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleDelete = (id) => {
    Swal.fire({
      title: "Are you sure?",
      text: "You won't be able to revert this!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Yes, delete it!",
    }).then(async (result) => {
      if (result.isConfirmed) {
        await callDeleteWithValueRestApis(Config.DELETEUSER + id, accessToken);
        getAllProfiles();
        getTotalRecords();
      }
    });
  };

  const handleDownload = async (type) => {
    const data = await callGetRestApis(
      Config.GETSINGLEUSERPROFILES + downloadCvId,
      accessToken
    );
    let userData = data?.data || [];
    let { firstname, lastname } = userData;
    let name = `${firstname} ${lastname}`;
    fetch(
      type === "pdf"
        ? process.env.REACT_APP_API_URL + Config.DOWNLOADASPDF + downloadCvId
        : process.env.REACT_APP_API_URL + Config.DOWNLOADASDOCX + downloadCvId,
      {
        method: "GET",
        headers: {
          token: accessToken,
        },
      }
    )
      .then((response) => {
        if (!response.ok) {
          throw new Error("Failed to download CV");
        }
        return response.blob();
      })
      .then((blob) => {
        const url = window.URL.createObjectURL(new Blob([blob]));
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", `${name}.${type}`);

        document.body.appendChild(link);
        link.click();
        link.parentNode.removeChild(link);
        handleDownloadClose();
        getTotalRecords();
      })
      .catch((error) => {
        console.error("Error downloading CV:", error);
      });
  };

  const handleDownloadClick = (event, id) => {
    setAnchorEl(event.currentTarget);
    setDownloadCvId(id);
  };

  const handleDownloadClose = () => {
    setAnchorEl(null);
  };

  const handleCopy = (id) => {
    const dataToSend = {
      id: id,
    };
    navigate(`/addresume/`, { state: dataToSend });
  };

  const handleChangeType = (value) => {
    setRowsPerPage(10);
    setPage(0);
    setProfileType(value);
  };

  const handleFavouriteToggle = async (id, favorite) => {
    let userObj = {
      cv_id: id,
    };
    await callPostWithValueRestApis(
      favorite === 1 ? Config.REMOVEFROMFAVOURITE : Config.ADDTOFAVOURITE,
      userObj
    )
      .then((res) => {
        Swal.fire({
          title: `${favorite === 1 ? "Removed" : "Added"}`,
          text: `${
            favorite === 1
              ? "Profile removed from favourites"
              : "Profile added to favourites"
          }`,
          icon: "success",
        });
        getAllProfiles();
        getTotalRecords();
      })
      .catch((err) => {
        console.log(err, "eee");
      });
  };
  return (
    <div>
      <Navbar title="Dashboard" />
      <div className="dashboard-container">
        <div class="header_content">
          <div class="button with-avatar" style={{ marginRight: "20px" }}>
            <Avatar
              variant="rounded"
              style={{
                backgroundColor: "rgba(11, 59, 91, 1)",
                marginRight: "10px",
                borderRadius: "50%",
              }}
            >
              <GroupAddOutlinedIcon style={{ color: "#fff" }} />
            </Avatar>
            <div>
              <p
                class="button-text-avatar"
                style={{ color: "rgba(25, 117, 201, 1)", marginBottom: "10px" }}
              >
                Total Number of CV
              </p>
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    fontSize: "25px",
                    fontWeight: 600,
                    color: "#0B3B5B",
                    margin: "0px",
                  }}
                >
                  {totalRecords.total}
                </p>
                <img
                  src={barIcon}
                  alt="logo"
                  className="navbar-logo"
                  style={{ width: "102px", height: "35px", marginLeft: "40px" }}
                />
              </div>
            </div>
          </div>
          <div class="button with-avatar" style={{ marginRight: "20px" }}>
            <Avatar
              variant="rounded"
              style={{
                backgroundColor: "rgba(216, 27, 42, 1)",
                marginRight: "10px",
                borderRadius: "50%",
              }}
            >
              <DeleteOutlineOutlinedIcon style={{ color: "#fff" }} />
            </Avatar>
            <div>
              <p
                class="button-text-avatar"
                style={{ color: "rgba(25, 117, 201, 1)", marginBottom: "10px" }}
              >
                Deleted CV
              </p>
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    fontSize: "25px",
                    fontWeight: 600,
                    color: "#D81B2A",
                    margin: "0px",
                  }}
                >
                  {totalRecords.deleted}
                </p>
                <img
                  src={barIcon}
                  alt="logo"
                  className="navbar-logo"
                  style={{
                    width: "102px",
                    height: "35px",
                    marginLeft: "40px",
                    filter:
                      "hue-rotate(0deg) saturate(100%) brightness(100%) contrast(100%) sepia(0%) saturate(4863%) hue-rotate(45deg)",
                  }}
                />
              </div>
            </div>
          </div>
          <div class="button with-avatar" style={{ marginRight: "20px" }}>
            <Avatar
              variant="rounded"
              style={{
                backgroundColor: "rgba(59, 181, 75, 1)",
                marginRight: "10px",
                borderRadius: "50%",
              }}
            >
              <FileDownloadOutlinedIcon style={{ color: "#fff" }} />
            </Avatar>
            <div>
              <p
                class="button-text-avatar"
                style={{ color: "rgba(25, 117, 201, 1)", marginBottom: "10px" }}
              >
                Download CV
              </p>
              <div style={{ display: "flex" }}>
                <p
                  style={{
                    fontSize: "25px",
                    fontWeight: 600,
                    color: "#3BB54B",
                    margin: "0px",
                  }}
                >
                  {totalRecords.download}
                </p>
                <img
                  src={barIcon}
                  alt="logo"
                  className="navbar-logo"
                  style={{
                    width: "102px",
                    height: "35px",
                    marginLeft: "40px",
                    filter:
                      "hue-rotate(0deg) saturate(100%) brightness(100%) contrast(100%) sepia(0%) saturate(4863%) hue-rotate(210deg)",
                  }}
                />
              </div>
            </div>
          </div>
          <div class="button with-avatar">
            <Link to="/addresume" class="action-button">
              <Avatar
                variant="rounded"
                style={{
                  backgroundColor: "rgba(25, 117, 201, 1)",
                  marginRight: "10px",
                  borderRadius: "50%",
                }}
              ></Avatar>
              <span
                class="button-text"
                style={{ color: "rgba(25, 117, 201, 1)" }}
              >
                ADD NEW
              </span>
            </Link>
          </div>
        </div>

        <div className="dashboard-header">
          <div
            className="search-container"
            style={{
              width: "calc(100% - 200px)",
              height: "48px",
              display: "flex",
              gap: "10px",
              borderRadius: "8px 8px 8px 8px",
              border: "1px solid rgba(218, 227, 248, 1)",
              opacity: "0.9",
              marginLeft: "20px",
            }}
          >
            <TextField
              value={search}
              placeholder="Search"
              className="search-input"
              InputProps={{
                disableUnderline: true,
                classes: {
                  notchedOutline: "no-border",
                },
                startAdornment: (
                  <InputAdornment position="start">
                    <Search />
                  </InputAdornment>
                ),
              }}
              style={{
                flex: "1",
                borderRadius: "0px 8px 8px 0px",
                fontSize: "14px",
                border: "none",
                outline: "none",
              }}
              onChange={(e) => {
                setSearch(e.target.value);
                setRowsPerPage(10);
                setPage(0);
              }}
            />
          </div>

          <FormControl>
            <InputLabel id="profile-type-label">Profile Type</InputLabel>
            <Select
              labelId="profile-type-label"
              id="profile-type-select"
              value={profileType}
              onChange={(e) => handleChangeType(e.target.value)}
              style={{ height: "48px", marginRight: "10px", width: "150px" }}
              label="Profile Type"
            >
              <MenuItem value={"All"}>All</MenuItem>
              <MenuItem value={"Contractor"}>Contractor</MenuItem>
              <MenuItem value={"Employee"}>Employee</MenuItem>
            </Select>
          </FormControl>
        </div>
        <TableContainer component={Paper} className="table-container">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell className="table-header">Name</TableCell>
                <TableCell className="table-header">Email</TableCell>
                <TableCell className="table-header">Profile Type</TableCell>
                <TableCell className="table-header">Status</TableCell>
                <TableCell className="table-header">Favourite</TableCell>
                <TableCell className="table-header">Action</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {userProfiles.length ? (
                userProfiles.map((item) => {
                  return (
                    <TableRow key={item.id}>
                      <TableCell>
                        <Button className="iconButton">
                          {item.firstname.charAt(0)}
                        </Button>
                        {item.firstname} {item.lastname}
                      </TableCell>
                      <TableCell>{item.email}</TableCell>
                      <TableCell>{item.profile_type}</TableCell>
                      <TableCell>
                        <Chip
                          label={item.status}
                          color={
                            item.status === "Complete" ? "success" : "error"
                          }
                        />
                      </TableCell>
                      <TableCell>
                        {item.favorite ? (
                          <Star
                            onClick={(e) =>
                              handleFavouriteToggle(item.id, item.favorite)
                            }
                            style={{ color: "yellow" }}
                          />
                        ) : (
                          <StarBorder
                            onClick={() =>
                              handleFavouriteToggle(item.id, item.favorite)
                            }
                          />
                        )}
                      </TableCell>
                      <TableCell>
                        <Tooltip
                          title="Edit"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                background: "#3BB54B",
                              },
                            },
                          }}
                        >
                          <Link
                            to={`/editresume/${item.id}`}
                            className="action-edit"
                          >
                            <Edit style={{ color: "green" }} />
                          </Link>
                        </Tooltip>
                        <Tooltip
                          title="Copy"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                background: "#1975C9",
                              },
                            },
                          }}
                        >
                          <Button
                            className="action-delete"
                            onClick={() => handleCopy(item.id)}
                          >
                            <ContentCopy style={{ color: "#1975C9" }} />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          title="Delete"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                background: "#D81B2A",
                              },
                            },
                          }}
                        >
                          <Button
                            className="action-delete"
                            onClick={() => handleDelete(item.id)}
                          >
                            <Delete style={{ color: "red" }} />
                          </Button>
                        </Tooltip>
                        <Tooltip
                          title="Download"
                          componentsProps={{
                            tooltip: {
                              sx: {
                                background: "#0B3B5B",
                              },
                            },
                          }}
                        >
                          <Button
                            className="action-download"
                            onClick={(e) => handleDownloadClick(e, item.id)}
                          >
                            <GetApp style={{ color: "#0B3B5B" }} />
                          </Button>
                          <Menu
                            id="download-menu"
                            anchorEl={anchorEl}
                            keepMounted
                            open={Boolean(anchorEl)}
                            onClose={handleDownloadClose}
                          >
                            <MenuItem onClick={() => handleDownload("doc")}>
                              Download as docx
                            </MenuItem>
                            <MenuItem onClick={() => handleDownload("pdf")}>
                              Download as pdf
                            </MenuItem>
                          </Menu>
                        </Tooltip>
                      </TableCell>
                    </TableRow>
                  );
                })
              ) : (
                <TableRow>
                  <TableCell colSpan={6} style={{ textAlign: "center" }}>
                    No Data Found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </div>
      <TablePagination
        className="table-pagination"
        component="div"
        count={count}
        page={page}
        onPageChange={handleChangePage}
        rowsPerPage={rowsPerPage}
        onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </div>
  );
};

export default Dashboard;
