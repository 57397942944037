import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import LoginPage from "../LoginPage/LoginPage";
import ProtectedRoute from "./ProtectedRoute";
import Dashboard from "../Dashboard/Dashboard";
import AddResume from "../Form/AddResume";
import EditResume from "../Form/EditResume";

export default function MainRoutes() {
  return (
    <Router>
      <Routes>
        <Route exact={true} path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route
          exact={true}
          path="/dashboard"
          element={
            <ProtectedRoute>
              <Dashboard />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/addresume"
          element={
            <ProtectedRoute>
              <AddResume />
            </ProtectedRoute>
          }
        />
        <Route
          exact
          path="/editresume/:id"
          element={
            <ProtectedRoute>
              <EditResume />
            </ProtectedRoute>
          }
        />
      </Routes>
    </Router>
  );
}
