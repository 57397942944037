import { callGetRestApis } from "../../Services/Api";
import Config from "../../utils/Config";

export const getAllUserProfiles = async (queryParams, accessToken) => {
  const result = await callGetRestApis(
    Config.GETALLUSERPROFILES + queryParams,
    accessToken
  );
  return result;
};

export const getSingleUserProfile = async (id, accessToken) => {
  const result = await callGetRestApis(
    Config.GETSINGLEUSERPROFILES + id,
    accessToken
  );
  return result;
};
