import { createSlice } from "@reduxjs/toolkit";

// define initial state for this slice
const initialState = {
  existingData: {
    existingAboutMe: [],
    existingExperience: [],
    existingTechnicalSkills: [],
    existingProject: [],
    existingTotalCount: 0,
  },
};

// define the slice, with reducers
export const form = createSlice({
  name: "form",
  initialState,
  reducers: {
    setExistingAboutMe: (state, action) => {
      state.existingData[action.payload.name] = action.payload.value;
    },
    setExistingExperience: (state, action) => {
      state.existingData[action.payload.name] = action.payload.value;
    },
    setExistingTechnicalSkills: (state, action) => {
      state.existingData[action.payload.name] = action.payload.value;
    },
    setExistingProject: (state, action) => {
      state.existingData[action.payload.name] = action.payload.value;
    },
    setTotalCount: (state, action) => {
      state.existingData["existingTotalCount"] = action.payload;
    },
  },
});

// export actions
export const {
  setExistingAboutMe,
  setTotalCount,
  setExistingExperience,
  setExistingTechnicalSkills,
  setExistingProject,
} = form.actions;

//selectors for extracting from state
export const selectExistingData = (state) => state.form.existingData;
