import { Button } from "@mui/material";
import React from "react";
import "./Navbar.css";
import { handleLogout } from "../../utils/utils";
import { useNavigate } from "react-router-dom";
import LogoutOutlinedIcon from "@mui/icons-material/LogoutOutlined";
import logo from "./logo.png";

const Navbar = (props) => {
  const navigate = useNavigate();
  return (
    <div className="navbar" style={{ backgroundColor: "#FFFF" }}>
      <div className="navbar-heading">
        <img
          src={logo}
          alt="logo"
          className="navbar-logo"
          onClick={() => navigate("/dashboard/")}
          style={{ cursor: "pointer", width: "178px", height: "76px" }}
        />
        <h1 className="navbar-title">{props?.title}</h1>
      </div>
      <div className="navbar-action">
        <Button className="logout-button" onClick={() => handleLogout()}>
          <LogoutOutlinedIcon style={{ color: "red", margin: "5px" }} /> Log out
        </Button>
      </div>
    </div>
  );
};

export default Navbar;
