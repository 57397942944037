import React from "react";
import { Modal, Box } from "@mui/material";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 900,
  height: 500,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const ReactModal = (props) => {
  const handleClose = () => {
    props.handleCloseModal(props.for);
  };

  return (
    <>
      <Modal
        open={props.open}
        onClose={handleClose}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={style}>{props.content}</Box>
      </Modal>
    </>
  );
};

export default ReactModal;
