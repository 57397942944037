const GETALLUSERPROFILES = "api/v1/";
const GETSINGLEUSERPROFILES = "api/v1/";
const ADDPROFILE = "api/v1/";
const DOWNLOADRESUME = "api/v1/download-cv-pdf/";
const EDITUSER = "api/v1/";
const DELETEUSER = "api/v1/";
const LOGIN = "api/v1/login";
const LOGOUT = "api/v1/logout";
const DOWNLOADASDOCX = "api/v1/download-cv-word/";
const DOWNLOADASPDF = "api/v1/download-cv-pdf/";
const IMAGEUPLOAD = "api/v1/images-upload/";
const EXITINGABOUTME = "api/v1/previous/about";
const EXITINGEXPERIENCE = "api/v1/previous/experience";
const EXITINGTECHNICALSKILLS = "api/v1/previous/technical-skills";
const EXITINGPROJECT = "api/v1/previous/project";
const ADDTOFAVOURITE = "api/v1/favorite";
const REMOVEFROMFAVOURITE = "api/v1/remove-favorite";
const TOTALRECORDS = "api/v1/total/records";

export default {
  GETALLUSERPROFILES,
  ADDPROFILE,
  DOWNLOADRESUME,
  EDITUSER,
  GETSINGLEUSERPROFILES,
  DELETEUSER,
  LOGOUT,
  LOGIN,
  DOWNLOADASDOCX,
  DOWNLOADASPDF,
  IMAGEUPLOAD,
  EXITINGABOUTME,
  EXITINGEXPERIENCE,
  EXITINGTECHNICALSKILLS,
  EXITINGPROJECT,
  ADDTOFAVOURITE,
  REMOVEFROMFAVOURITE,
  TOTALRECORDS,
};
