import React, { useState, useEffect } from "react";
import { TextField, Card, CardContent, Tooltip } from "@mui/material";
import "./AddResume.css"; // Import CSS file
import {
  Button,
  Avatar,
  FormControl,
  RadioGroup,
  Radio,
  FormControlLabel,
  Checkbox,
  FormGroup,
  Box,
  Snackbar,
} from "@mui/material";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  callPostWithValueRestApis,
  callPutWithValueRestApis,
} from "../../Services/Api";
import Config from "../../utils/Config";
import Navbar from "../Navbar.jsx/Navbar";
import { getAccessToken } from "../../utils/utils";
import Swal from "sweetalert2";
import { CloudUpload, ArrowUpward, ArrowDownward } from "@mui/icons-material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { setCloseModal, setOpenModal } from "../../redux/ReactModal/ReactModal";
import { shallowEqual, useDispatch, useSelector } from "react-redux";
import { selectOpenModal } from "../../redux/ReactModal/ReactModal";
import ReactModal from "../Modal/ReactModal";
import { getSingleUserProfile } from "../../redux/dashboard/dashboardAPI";
import ReactTable from "../ReactTable/ReactTable";
import {
  aboutMeModalColumns,
  experienceModalColumns,
  projectModalColumns,
  technicalSkillsModalColumns,
} from "./config";
import {
  getExistingAboutMe,
  getExistingExperience,
  getExistingProjects,
  getExistingTechnicalSkills,
} from "../../redux/Form/formAPI";
import {
  selectExistingData,
  setExistingAboutMe,
  setExistingExperience,
  setExistingProject,
  setExistingTechnicalSkills,
  setTotalCount,
} from "../../redux/Form/form";

const AddResume = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const accessToken = getAccessToken();
  const [userInfo, setUserInfo] = useState({
    profileImage: "",
    profileType: "",
    firstName: "",
    lastName: "",
    designation: "",
    email: "",
    about: "",
    postGraduationCourse: "",
    postGraduationInstituteName: "",
    postGraduationduration: "",
    postGraduationLocation: "",
    graduationCourse: "",
    graduationInstituteName: "",
    graduationduration: "",
    graduationLocation: "",
  });
  const [projects, setProjects] = useState([]);
  const [deletedprojects, setDeletedProjects] = useState([]);
  const [experience, setExperience] = useState([]);
  const [technicalSkills, setTechnicalSkills] = useState([]);
  const [deletedExperience, setDeletedExperience] = useState([]);
  const [deletedTechnicalSkills, setDeletedTechnicalSkills] = useState([]);
  const [imgPreview, setImgPreview] = useState(null);
  const [selectedCardIndex, setSelectedCardIndex] = useState(null);
  const [isContractor, setIsContractor] = useState("No");
  const [addToFavorites, setAddToFavorites] = useState(false);
  const [showExperience, setShowExperience] = useState(false);
  const { openModal } = useSelector(selectOpenModal, shallowEqual);
  const [profileSaved, setProfileSaved] = useState(false);
  const {
    existingAboutMe,
    existingExperience,
    existingTechnicalSkills,
    existingProject,
  } = useSelector(selectExistingData, shallowEqual);
  const vertical = "bottom";
  const horizontal = "left";

  const addProject = () => {
    let allProjects = [
      ...projects,
      {
        project_name: "",
        project_details: "",
        project_technologies: "",
        roles_responsibility: "",
        project_start_date: "",
        project_end_date: "",
        project_role: "",
        current: "",
      },
    ];
    handleAutoSave("projects", allProjects);
  };

  const addExperience = () => {
    let allExperience = [
      ...experience,
      {
        start_date: "",
        end_date: "",
        current_company: false,
        company_name: "",
        address: "",
        designation: "",
        DESCRIPTION: "",
      },
    ];
    handleAutoSave("experience", allExperience);
  };

  const addTechnicalSkills = () => {
    let allSkills = [
      ...technicalSkills,
      {
        skill_title: "",
        skills: "",
      },
    ];
    handleAutoSave("skill", allSkills);
  };

  useEffect(() => {
    let queryParams = `?page=1&pagesize=10`;
    getExistingDataAboutMe(queryParams);
    getExistingDataExperience(queryParams);
    getExistingDataTechnicalSkills(queryParams);
    getExistingDataProjects(queryParams);
    if (props?.id || location.state?.id) {
      getUserProfile(props?.id || location.state?.id);
    }
    localStorage.removeItem("userId");
  }, []);

  const getExistingDataAboutMe = async (queryParams) => {
    const { data } = await getExistingAboutMe(queryParams, accessToken);
    dispatch(setExistingAboutMe({ name: "existingAboutMe", value: data }));
    dispatch(setTotalCount(data.totalCount));
  };

  const getExistingDataExperience = async (queryParams) => {
    const { data } = await getExistingExperience(queryParams, accessToken);
    dispatch(
      setExistingExperience({ name: "existingExperience", value: data })
    );
  };
  const getExistingDataTechnicalSkills = async (queryParams) => {
    const { data } = await getExistingTechnicalSkills(queryParams, accessToken);
    dispatch(
      setExistingTechnicalSkills({
        name: "existingTechnicalSkills",
        value: data,
      })
    );
  };
  const getExistingDataProjects = async (queryParams) => {
    const { data } = await getExistingProjects(queryParams, accessToken);
    dispatch(setExistingProject({ name: "existingProject", value: data }));
  };

  const getUserProfile = async (id) => {
    const { status, data } = await getSingleUserProfile(id, accessToken);
    if (status === 200) {
      let {
        Designation,
        email,
        firstname,
        graduation_course,
        graduation_university,
        lastname,
        pg_course,
        pg_university,
        summary,
        project,
        graduation_duration,
        graduation_location,
        pg_duration,
        pg_location,
        skillsList,
        experience,
        profile_type,
        cv_profile_image,
        contractor_employess,
        favorite,
        show_experience,
      } = data;
      setUserInfo((prevState) => ({
        ...prevState,
        firstName: firstname,
        profileType: profile_type,
        lastName: lastname,
        designation: Designation,
        email: email,
        about: summary,
        postGraduationCourse: pg_course,
        postGraduationInstituteName: pg_university,
        graduationCourse: graduation_course,
        graduationInstituteName: graduation_university,
        postGraduationLocation: pg_location,
        postGraduationduration: pg_duration,
        graduationduration: graduation_duration,
        graduationLocation: graduation_location,
      }));
      setProjects(project);
      setTechnicalSkills(skillsList);
      setExperience(experience);
      setImgPreview(cv_profile_image);
      setIsContractor(contractor_employess === 1 ? "Yes" : "No");
      setAddToFavorites(favorite === 1 ? true : false);
      setShowExperience(show_experience === 1 ? true : false);
    }
  };

  const handleDateString = (date) => {
    let originalDate = date ? new Date(date) : new Date();
    let year = originalDate.getFullYear();
    let month = ("0" + (originalDate.getMonth() + 1)).slice(-2);
    let day = ("0" + originalDate.getDate()).slice(-2);

    let newDateStr = year + "-" + month + "-" + day + "T05:51:23.894Z";
    return newDateStr;
  };

  const handleCardChange = (index, field, value, cardType) => {
    let dateField = [
      "start_date",
      "end_date",
      "project_start_date",
      "project_end_date",
    ];
    let updateValue = value;
    if (field === "current") {
      updateValue = value ? 1 : 0;
    }
    if (dateField.includes(field)) {
      updateValue = handleDateString(updateValue);
    }
    const cardState =
      cardType === "project"
        ? projects
        : cardType === "experience"
        ? experience
        : technicalSkills;
    const updatedCardState = [...cardState];
    updatedCardState[index][field] = updateValue;
    if ((field === "current_company" || field === "current") && updateValue) {
      let key = field === "current_company" ? "end_date" : "project_end_date";
      updatedCardState[index][key] = handleDateString();
    }
    cardType === "project"
      ? setProjects(updatedCardState)
      : cardType === "experience"
      ? setExperience(updatedCardState)
      : setTechnicalSkills(updatedCardState);
  };

  const deleteCard = (index, cardType) => {
    const cardState =
      cardType === "project"
        ? projects
        : cardType === "experience"
        ? experience
        : technicalSkills;
    const updatedCardState = [...cardState];
    if (updatedCardState[index].id) {
      let deletedCard = updatedCardState[index];
      deletedCard["active"] = 0;
      cardType === "project"
        ? setDeletedProjects([...deletedprojects, deletedCard])
        : cardType === "experience"
        ? setDeletedExperience([...deletedExperience, deletedCard])
        : setDeletedTechnicalSkills([...deletedTechnicalSkills, deletedCard]);
    }
    updatedCardState.splice(index, 1);
    cardType === "project"
      ? setProjects(updatedCardState)
      : cardType === "experience"
      ? setExperience(updatedCardState)
      : setTechnicalSkills(updatedCardState);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setUserInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
  };

  const handleAboutChange = (value) => {
    setUserInfo((prevState) => ({
      ...prevState,
      about: value,
    }));
  };

  const handleAutoSave = (type, data) => {
    const id = localStorage.getItem("userId");
    if (id) handleSaveOnChange(id, data, type);

    if (props?.id) handleSaveOnChange(props?.id, data, type);
  };

  const SaveEditedData = (array1, array2, type) => {
    array1.forEach((obj1, index1) => {
      array2.forEach((obj2) => {
        const keysToCheck = Object.keys(obj1).filter(
          (key) =>
            key !== "current_company" &&
            key !== "project_start_date" &&
            key !== "project_end_date" &&
            key !== "start_date" &&
            key !== "end_date" &&
            key !== "current"
        );
        const match = keysToCheck.every((key) => obj1[key] === obj2[key]);

        if (match) {
          // Replace object in array1 with matching object from array2
          array1[index1] = obj2;
        }
      });
    });
    if (type === "project") {
      setProjects(array1);
    }
    if (type === "experience") {
      setExperience(array1);
    }
    if (type === "skill") {
      setTechnicalSkills(array1);
    }
  };

  const handleSaveOnChange = async (id, data, type) => {
    const {
      firstName,
      lastName,
      designation,
      email,
      about,
      postGraduationCourse,
      postGraduationInstituteName,
      graduationCourse,
      graduationInstituteName,
      postGraduationLocation,
      postGraduationduration,
      graduationduration,
      graduationLocation,
      profileImage,
      profileType,
    } = userInfo;
    let filteredProjects = projects.filter((obj) =>
      Object.values(obj).some(
        (val) => val !== null && val !== "" && val !== false
      )
    );
    let filteredTechnicalSkills = technicalSkills.filter((obj) =>
      Object.values(obj).some(
        (val) => val !== null && val !== "" && val !== false
      )
    );
    let filteredExperience = experience.filter((obj) =>
      Object.values(obj).some(
        (val) => val !== null && val !== "" && val !== false
      )
    );
    const userObj = {
      firstname: firstName,
      profile_type: profileType,
      lastname: lastName,
      email: email,
      role: designation,
      summary: about,
      pg_course: postGraduationCourse,
      pg_university: postGraduationInstituteName,
      pg_duration: postGraduationduration,
      pg_location: postGraduationLocation,
      graduation_course: graduationCourse,
      graduation_university: graduationInstituteName,
      graduation_duration: graduationduration,
      graduation_location: graduationLocation,
      projectList: id
        ? [...filteredProjects, ...deletedprojects]
        : filteredProjects,
      skillsList: id
        ? [...filteredTechnicalSkills, ...deletedTechnicalSkills]
        : filteredTechnicalSkills,
      experience: id
        ? [...filteredExperience, ...deletedExperience]
        : filteredExperience,
      contractor_employess: isContractor === "Yes" ? 1 : 0,
      favorite: addToFavorites ? 1 : 0,
      show_experience: showExperience ? 1 : 0,
    };
    if (id) {
      await callPutWithValueRestApis(Config.EDITUSER + id, userObj, accessToken)
        .then(async (res) => {
          if (res?.status === 200) {
            if (type === "projects") {
              SaveEditedData(data, res?.data.project, "project");
              SaveEditedData(technicalSkills, res?.data.skillsList, "skill");
              SaveEditedData(experience, res?.data.experience, "experience");
            }
            if (type === "skill") {
              SaveEditedData(data, res?.data.skillsList, "skill");
              SaveEditedData(projects, res?.data.project, "project");
              SaveEditedData(experience, res?.data.experience, "experience");
            }

            if (type === "experience") {
              SaveEditedData(data, res?.data.experience, "experience");
              SaveEditedData(projects, res?.data.project, "project");
              SaveEditedData(technicalSkills, res?.data.skillsList, "skill");
            }
            if (profileImage) {
              const formData = new FormData();
              formData.append("image", profileImage);
              await callPostWithValueRestApis(
                Config.IMAGEUPLOAD + id,
                formData,
                "img"
              )
                .then((res) => {
                  setProfileSaved(true);
                  localStorage.removeItem("newUser");
                })
                .catch((err) => {
                  Swal.fire({
                    title: "Oops...",
                    text: "Something went wrong!",
                    icon: "error",
                  });
                });
            } else {
              setProfileSaved(true);
              localStorage.removeItem("newUser");
            }
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Oops...",
            text: "Something went wrong!",
            icon: "error",
          });
        });
    } else {
      await callPostWithValueRestApis(Config.ADDPROFILE, userObj)
        .then(async (res) => {
          if (res?.status === 200) {
            if (profileImage) {
              const formData = new FormData();
              formData.append("image", profileImage);
              let id = res.data.id;
              await callPostWithValueRestApis(
                Config.IMAGEUPLOAD + id,
                formData,
                "img"
              )
                .then((res) => {
                  setProfileSaved(true);
                })
                .catch((err) => {
                  Swal.fire({
                    title: "Oops...",
                    text: "Something went wrong!",
                    icon: "error",
                  });
                });
            } else {
              setProfileSaved(true);
              localStorage.setItem("userId", res.data.id);
              localStorage.setItem("newUser", true);
            }
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Oops...",
            text: "Something went wrong!",
            icon: "error",
          });
        });
    }
  };

  const handleSave = async () => {
    const {
      firstName,
      lastName,
      designation,
      email,
      about,
      postGraduationCourse,
      postGraduationInstituteName,
      graduationCourse,
      graduationInstituteName,
      postGraduationLocation,
      postGraduationduration,
      graduationduration,
      graduationLocation,
      profileImage,
      profileType,
    } = userInfo;
    let filteredProjects = projects.filter((obj) =>
      Object.values(obj).some(
        (val) => val !== null && val !== "" && val !== false
      )
    );
    let filteredTechnicalSkills = technicalSkills.filter((obj) =>
      Object.values(obj).some(
        (val) => val !== null && val !== "" && val !== false
      )
    );
    let filteredExperience = experience.filter((obj) =>
      Object.values(obj).some(
        (val) => val !== null && val !== "" && val !== false
      )
    );
    const userObj = {
      firstname: firstName,
      profile_type: profileType,
      lastname: lastName,
      email: email,
      role: designation,
      summary: about,
      pg_course: postGraduationCourse,
      pg_university: postGraduationInstituteName,
      pg_duration: postGraduationduration,
      pg_location: postGraduationLocation,
      graduation_course: graduationCourse,
      graduation_university: graduationInstituteName,
      graduation_duration: graduationduration,
      graduation_location: graduationLocation,
      projectList: props?.id
        ? [...filteredProjects, ...deletedprojects]
        : filteredProjects,
      skillsList: props?.id
        ? [...filteredTechnicalSkills, ...deletedTechnicalSkills]
        : filteredTechnicalSkills,
      experience: props?.id
        ? [...filteredExperience, ...deletedExperience]
        : filteredExperience,
      contractor_employess: isContractor === "Yes" ? 1 : 0,
      favorite: addToFavorites ? 1 : 0,
      show_experience: showExperience ? 1 : 0,
    };
    const userId = localStorage.getItem("userId");
    if (props?.id || userId) {
      const editUserId = userId ? userId : props?.id;
      await callPutWithValueRestApis(
        Config.EDITUSER + editUserId,
        userObj,
        accessToken
      )
        .then(async (res) => {
          if (res?.status === 200) {
            if (profileImage) {
              const formData = new FormData();
              formData.append("image", profileImage);
              await callPostWithValueRestApis(
                Config.IMAGEUPLOAD + editUserId,
                formData,
                "img"
              )
                .then((res) => {
                  navigate("/dashboard/");
                })
                .catch((err) => {
                  Swal.fire({
                    title: "Oops...",
                    text: "Something went wrong!",
                    icon: "error",
                  });
                });
            } else {
              navigate("/dashboard/");
            }
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Oops...",
            text: "Something went wrong!",
            icon: "error",
          });
        });
    } else {
      await callPostWithValueRestApis(Config.ADDPROFILE, userObj)
        .then(async (res) => {
          if (res?.status === 200) {
            if (profileImage) {
              const formData = new FormData();
              formData.append("image", profileImage);
              let id = res.data.id;
              await callPostWithValueRestApis(
                Config.IMAGEUPLOAD + id,
                formData,
                "img"
              )
                .then((res) => {
                  navigate("/dashboard/");
                })
                .catch((err) => {
                  Swal.fire({
                    title: "Oops...",
                    text: "Something went wrong!",
                    icon: "error",
                  });
                });
            } else {
              navigate("/dashboard/");
            }
          }
        })
        .catch((err) => {
          Swal.fire({
            title: "Oops...",
            text: "Something went wrong!",
            icon: "error",
          });
        });
    }
  };

  const handleClose = (event, reason) => {
    if (reason === "clickaway") {
      return;
    }

    setProfileSaved(false);
  };

  const moveCard = (index, direction, cardType) => {
    const newIndex = direction === "down" ? index + 1 : index - 1;
    const cardState =
      cardType === "project"
        ? projects
        : cardType === "experience"
        ? experience
        : technicalSkills;
    const updatedCardState = [...cardState];
    const temp = updatedCardState[index];
    updatedCardState[index] = updatedCardState[newIndex];
    updatedCardState[newIndex] = temp;
    cardType === "project"
      ? setProjects(updatedCardState)
      : cardType === "experience"
      ? setExperience(updatedCardState)
      : setTechnicalSkills(updatedCardState);
  };

  const handleImageUpload = (e) => {
    const file = e.target.files[0];
    setUserInfo((prevState) => ({
      ...prevState,
      profileImage: file,
    }));
    const reader = new FileReader();
    reader.onloadend = () => {
      setImgPreview(reader.result);
    };
    reader.readAsDataURL(file);
  };

  const handleOpenModal = (field) => {
    dispatch(setOpenModal(field));
  };

  const handleCloseModal = (type, data, index) => {
    if (type === "aboutMe" && data) {
      setUserInfo((prevState) => ({
        ...prevState,
        about: data?.summary,
      }));
    } else if (type === "experience" && data) {
      let newDescription = experience;
      newDescription[index].DESCRIPTION = data.description;
      setExperience(newDescription);
    } else if (type === "technicalSkills" && data) {
      let newData = technicalSkills;
      newData[index].skill_title = data.skill_title;
      newData[index].skills = data.skills;
      setTechnicalSkills(newData);
    } else if (type === "project" && data) {
      let newData = projects;
      newData[index].project_name = data.project_name;
      newData[index].project_details = data.project_details;
      newData[index].project_technologies = data.project_technologies;
      newData[index].roles_responsibility = data.roles_responsibility;
      newData[index].project_start_date = data.project_start_date;
      newData[index].project_end_date = data.project_end_date;
      newData[index].current = data.current;
      setProjects(newData);
    }
    dispatch(setCloseModal(type));
    let queryParams = `?page=1&pagesize=10`;
    getExistingDataAboutMe(queryParams);
    getExistingDataExperience(queryParams);
    getExistingDataTechnicalSkills(queryParams);
    getExistingDataProjects(queryParams);
  };

  const handlePageChange = (type, newPage, rows) => {
    let queryParams = `?page=${newPage + 1}&pagesize=${rows}`;
    handlePaginationApiCall(type, queryParams);
  };

  const handleChangeRowsPerPage = (type, rows) => {
    let queryParams = `?page=1&pagesize=${rows}`;
    handlePaginationApiCall(type, queryParams);
  };

  const handlePaginationApiCall = (type, queryParams) => {
    if (type === "aboutMe") {
      getExistingDataAboutMe(queryParams);
    } else if (type === "experience") {
      getExistingDataExperience(queryParams);
    } else if (type === "technicalSkills") {
      getExistingDataTechnicalSkills(queryParams);
    } else if (type === "project") {
      getExistingDataProjects(queryParams);
    }
  };

  const getModalType = () => {
    if (openModal.aboutMe) return "aboutMe";
    if (openModal.experience) return "experience";
    if (openModal.technicalSkills) return "technicalSkills";
    if (openModal.project) return "project";
    return "";
  };

  const getModalTitle = () => {
    if (openModal.aboutMe) return "About Me";
    if (openModal.experience) return "Experience";
    if (openModal.technicalSkills) return "Technical Skills";
    if (openModal.project) return "Projects";
    return "";
  };

  const getModalTableColumns = () => {
    if (openModal.aboutMe) return aboutMeModalColumns;
    if (openModal.experience) return experienceModalColumns;
    if (openModal.technicalSkills) return technicalSkillsModalColumns;
    if (openModal.project) return projectModalColumns;
    return [];
  };

  const getModalTableData = () => {
    if (openModal.aboutMe) return existingAboutMe.data;
    if (openModal.experience) return existingExperience.data;
    if (openModal.technicalSkills) return existingTechnicalSkills.data;
    if (openModal.project) return existingProject.data;
    return [];
  };

  const handleSearch = () => {
    if (openModal.project) return true;
    return false;
  };

  const getModalTableDataTotlCount = () => {
    if (openModal.aboutMe) return existingAboutMe.totalCount;
    if (openModal.experience) return existingExperience.totalCount;
    if (openModal.technicalSkills) return existingTechnicalSkills.totalCount;
    if (openModal.project) return existingProject.totalCount;
    return [];
  };

  const handleSearchInput = (type, data, listType) => {
    let queryParams = `?search=${data}&page=1&pagesize=10`;
    let queryParamsFavourite = `?search=${data}&page=1&pagesize=10&favorite=true`;
    handlePaginationApiCall(
      type,
      listType === "All" ? queryParams : queryParamsFavourite
    );
  };

  const handleTypeChange = (type, data, search) => {
    let queryParams = `?search=${search}&page=1&pagesize=10`;
    let queryParamsFavourite = `?search=${search}&page=1&pagesize=10&favorite=true`;
    handlePaginationApiCall(
      type,
      data === "All" ? queryParams : queryParamsFavourite
    );
  };

  const existingButton = (field, index) => {
    return (
      <Button
        className="project-button"
        variant="contained"
        color="primary"
        onClick={() => {
          handleOpenModal(field);
          if (field !== "aboutMe") setSelectedCardIndex(index);
        }}
        style={{ marginLeft: field === "experience" ? "auto" : "10px" }}
      >
        Select From Existing
      </Button>
    );
  };

  const handleFocus = (type) => {
    const id = localStorage.getItem("userId");
    const { firstName, designation, about } = userInfo;
    if (
      !id &&
      firstName &&
      designation &&
      about &&
      !props?.id &&
      type === "education"
    )
      handleSaveOnChange();
  };
  const formTextField = (label, name, value, required, type) => {
    return (
      <TextField
        className="form-field"
        label={label}
        variant="outlined"
        margin="normal"
        onChange={(e) => {
          handleChange(e);
        }}
        name={name}
        value={value}
        onFocus={(e) => {
          handleFocus(type);
        }}
        // required={required}
        // helperText={errMsg[name] ? `${label} cannot be empty` : ""}
        // error={errMsg[name]}
      />
    );
  };

  return (
    <div>
      <Navbar title="Make Your Resume" />
      <div className="add-resume-container">
        <div className="add-resume-header">
          <h2>{props?.id ? "Edit Resume" : "Add Resume"}</h2>
          <Link to={"/dashboard"} className="back-button">
            Go To Dashboard
          </Link>
        </div>
        <form>
          {/* Basic Info */}
          <div className="form-section">
            <div className="form-section">
              <div className="form-field">
                <label htmlFor="image-upload">
                  {imgPreview ? (
                    <Avatar
                      alt="Profile Image"
                      src={imgPreview}
                      style={{ width: 100, height: 100, cursor: "pointer" }}
                    />
                  ) : (
                    <Avatar
                      alt="Upload Image"
                      style={{ width: 100, height: 100, cursor: "pointer" }}
                    >
                      <CloudUpload style={{ fontSize: 40 }} />
                    </Avatar>
                  )}
                </label>
                <input
                  accept="image/*"
                  style={{ display: "none" }}
                  id="image-upload"
                  type="file"
                  onChange={handleImageUpload}
                />
              </div>
            </div>
            <div
              className="form-field"
              style={{ display: "flex", width: "100%" }}
            >
              {formTextField(
                "Profile Type",
                "profileType",
                userInfo.profileType,
                false
              )}
            </div>
            <div
              className="form-field"
              style={{ display: "flex", width: "100%" }}
            >
              {formTextField(
                "First Name",
                "firstName",
                userInfo.firstName,
                true
              )}
              <TextField
                className="form-field"
                label="Last Name"
                variant="outlined"
                margin="normal"
                style={{ width: "49%", marginLeft: "10px" }}
                fullWidth
                onChange={(e) => handleChange(e)}
                name="lastName"
                value={userInfo.lastName}
              />
            </div>
            <div
              className="form-field"
              style={{ display: "flex", width: "100%" }}
            >
              {formTextField(
                "Designation",
                "designation",
                userInfo.designation,
                true
              )}
              <TextField
                className="form-field"
                label="Email ID"
                variant="outlined"
                margin="normal"
                style={{ width: "49%", marginLeft: "10px" }}
                type="email"
                onChange={(e) => handleChange(e)}
                name="email"
                value={userInfo.email}
              />
            </div>
            <div
              className="project-section"
              style={{ display: "flex", alignItems: "center" }}
            >
              <h3 style={{ flex: "1" }}>About Me</h3>
              {existingButton("aboutMe")}
            </div>
            <ReactQuill
              theme="snow"
              value={userInfo.about}
              onChange={(e) => handleAboutChange(e)}
              style={{ height: "200px" }}
            />
          </div>

          {/* Education */}
          <div className="form-section">
            <h3 style={{ marginTop: "70px" }}>Education</h3>
            <p>Post-Graduation</p>
            <div
              className="form-field"
              style={{ display: "flex", width: "100%" }}
            >
              {formTextField(
                "Course",
                "postGraduationCourse",
                userInfo.postGraduationCourse,
                true
              )}
              <TextField
                label="Institution or University"
                variant="outlined"
                className="form-field"
                margin="normal"
                onChange={(e) => handleChange(e)}
                style={{ width: "49%", marginLeft: "10px" }}
                name="postGraduationInstituteName"
                value={userInfo.postGraduationInstituteName}
              />
            </div>
            <div
              className="form-field"
              style={{ display: "flex", width: "100%" }}
            >
              {formTextField(
                "Location",
                "postGraduationLocation",
                userInfo.postGraduationLocation,
                true
              )}
              <TextField
                className="form-field"
                label="Completed"
                variant="outlined"
                margin="normal"
                style={{ width: "49%", marginLeft: "10px" }}
                fullWidth
                onChange={(e) => handleChange(e)}
                name="postGraduationduration"
                value={userInfo.postGraduationduration}
              />
            </div>
            <p>Graduation</p>
            <div
              className="form-field"
              style={{ display: "flex", width: "100%" }}
            >
              {formTextField(
                "Course",
                "graduationCourse",
                userInfo.graduationCourse,
                true,
                "education"
              )}
              <TextField
                className="form-field"
                label="Institution or University"
                variant="outlined"
                style={{ width: "49%", marginLeft: "10px" }}
                margin="normal"
                onChange={(e) => handleChange(e)}
                name="graduationInstituteName"
                value={userInfo.graduationInstituteName}
              />
            </div>
            <div
              className="form-field"
              style={{ display: "flex", width: "100%" }}
            >
              {formTextField(
                "Location",
                "graduationLocation",
                userInfo.graduationLocation,
                true
              )}
              <TextField
                className="form-field"
                label="Completed"
                variant="outlined"
                margin="normal"
                style={{ width: "49%", marginLeft: "10px" }}
                fullWidth
                onChange={(e) => handleChange(e)}
                name="graduationduration"
                value={userInfo.graduationduration}
              />
            </div>
          </div>

          {/* Working Experience */}
          <div
            className="form-section"
            style={{ display: "flex", alignItems: "center" }}
          >
            <h3 style={{ flex: "1" }}>Working Experience</h3>
            <FormControl component="fieldset">
              <FormGroup>
                <Box mt={2}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        checked={showExperience}
                        onChange={(event) =>
                          setShowExperience(event.target.checked)
                        }
                      />
                    }
                    label="Show In CV"
                  />
                </Box>
              </FormGroup>
            </FormControl>
            <Button
              variant="contained"
              color="primary"
              onClick={addExperience}
              style={{ marginTop: "10px" }}
            >
              Add Experience
            </Button>
          </div>
          {experience.map((exp, index) => (
            <Card key={index} style={{ marginBottom: "10px" }}>
              <CardContent>
                <div className="form-field">
                  <TextField
                    label="Start Date"
                    variant="outlined"
                    margin="normal"
                    type="date"
                    value={
                      exp.start_date
                        ? exp.start_date.substring(0, 10)
                        : exp.start_date
                    }
                    onChange={(e) =>
                      handleCardChange(
                        index,
                        "start_date",
                        e.target.value,
                        "experience"
                      )
                    }
                    style={{ marginRight: "15px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      placeholder: exp.start_date ? undefined : "MM/DD/YYYY",
                    }}
                  />
                  <TextField
                    label="End Date"
                    variant="outlined"
                    margin="normal"
                    type="date"
                    disabled={exp.current_company ? true : false}
                    value={
                      exp.end_date
                        ? exp.end_date.substring(0, 10)
                        : exp.end_date
                    }
                    onChange={(e) =>
                      handleCardChange(
                        index,
                        "end_date",
                        e.target.value,
                        "experience"
                      )
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      placeholder: exp.end_date ? undefined : "MM/DD/YYYY",
                    }}
                  />
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={exp.current_company}
                        onChange={(e) =>
                          handleCardChange(
                            index,
                            "current_company",
                            e.target.checked,
                            "experience"
                          )
                        }
                      />
                      Currently Work Here
                    </label>
                  </div>
                </div>
                <TextField
                  label="Company Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={exp.company_name}
                  onChange={(e) =>
                    handleCardChange(
                      index,
                      "company_name",
                      e.target.value,
                      "experience"
                    )
                  }
                />
                <TextField
                  label="Company Location"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={exp.address}
                  onChange={(e) =>
                    handleCardChange(
                      index,
                      "address",
                      e.target.value,
                      "experience"
                    )
                  }
                  InputLabelProps={{
                    shrink: true,
                  }}
                  InputProps={{
                    placeholder: exp.address ? undefined : "Company Location",
                  }}
                />
                <TextField
                  label="Designation"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={exp.designation}
                  onChange={(e) =>
                    handleCardChange(
                      index,
                      "designation",
                      e.target.value,
                      "experience"
                    )
                  }
                />
                {/* Responsibilities */}
                <div>
                  <div
                    className="project-section"
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <h4 style={{ marginTop: "15px", marginBottom: "5px" }}>
                      Responsibilities
                    </h4>
                    {existingButton("experience", index)}
                  </div>

                  <ReactQuill
                    theme="snow"
                    value={exp.DESCRIPTION}
                    onChange={(e) =>
                      handleCardChange(index, "DESCRIPTION", e, "experience")
                    }
                    style={{ height: "200px", marginBottom: "10px" }} // Adjust the height as needed
                  />
                </div>
                <Button
                  variant="contained"
                  color="error"
                  style={{ marginTop: "45px" }}
                  onClick={() => deleteCard(index, "experience")}
                >
                  Delete Experience
                </Button>
                {index !== experience.length - 1 && (
                  <Tooltip title="Move Downward">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => moveCard(index, "down", "experience")}
                      style={{ marginLeft: "5px", marginTop: "45px" }}
                    >
                      <ArrowDownward />
                    </Button>
                  </Tooltip>
                )}
                {index !== 0 && (
                  <Tooltip title="Move Upward">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => moveCard(index, "up", "experience")}
                      style={{ marginLeft: "5px", marginTop: "45px" }}
                    >
                      <ArrowUpward />
                    </Button>
                  </Tooltip>
                )}
              </CardContent>
            </Card>
          ))}

          {/* Technologies */}
          <div
            className="project-section"
            style={{ display: "flex", alignItems: "center" }}
          >
            <h3 style={{ flex: "1" }}>Technical Skills</h3>
            <Button
              className="project-button"
              variant="contained"
              color="primary"
              onClick={addTechnicalSkills}
              style={{ marginLeft: "auto" }}
            >
              Add Technical Skills
            </Button>
            {/* {existingButton("technicalSkills")} */}
          </div>
          {technicalSkills.map((skill, index) => (
            <Card
              className="project-card"
              key={index}
              style={{ marginTop: "10px" }}
            >
              <CardContent>
                {existingButton("technicalSkills", index)}
                <TextField
                  label="Skill Title"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={skill.skill_title}
                  onChange={(e) =>
                    handleCardChange(
                      index,
                      "skill_title",
                      e.target.value,
                      "technicalSkills"
                    )
                  }
                />
                <TextField
                  label="Skills"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={skill.skills}
                  onChange={(e) =>
                    handleCardChange(
                      index,
                      "skills",
                      e.target.value,
                      "technicalSkills"
                    )
                  }
                />
                <Button
                  variant="contained"
                  color="error"
                  style={{ margin: "5px" }}
                  onClick={() => deleteCard(index, "technicalSkills")}
                >
                  Delete Technical Skill
                </Button>

                {index !== technicalSkills.length - 1 && (
                  <Tooltip title="Move Downward">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => moveCard(index, "down", "technicalSkills")}
                      style={{ marginLeft: "5px" }}
                    >
                      <ArrowDownward />
                    </Button>
                  </Tooltip>
                )}
                {index !== 0 && (
                  <Tooltip title="Move Upward">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => moveCard(index, "up", "technicalSkills")}
                      style={{ marginLeft: "5px" }}
                    >
                      <ArrowUpward />
                    </Button>
                  </Tooltip>
                )}
              </CardContent>
            </Card>
          ))}

          {/* Projects */}
          <div
            className="project-section"
            style={{ display: "flex", alignItems: "center" }}
          >
            <h3 style={{ flex: "1" }}>Projects</h3>
            <Button
              className="project-button"
              variant="contained"
              color="primary"
              onClick={addProject}
              style={{ marginLeft: "auto" }}
            >
              Add Project
            </Button>
          </div>
          {projects.map((project, index) => (
            <Card
              className="project-card"
              key={index}
              style={{ marginTop: "10px" }}
            >
              <CardContent>
                <div style={{ display: "flex" }}>
                  {existingButton("project", index)}
                  <p style={{ marginLeft: "auto" }}>{`Project ${index + 1}`}</p>
                </div>
                <div className="form-field">
                  <TextField
                    label="Start Date"
                    variant="outlined"
                    margin="normal"
                    type="date"
                    value={
                      project.project_start_date
                        ? project.project_start_date.substring(0, 10)
                        : project.project_start_date
                    }
                    onChange={(e) =>
                      handleCardChange(
                        index,
                        "project_start_date",
                        e.target.value,
                        "project"
                      )
                    }
                    style={{ marginRight: "15px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      placeholder: project.project_start_date
                        ? undefined
                        : "MM/DD/YYYY",
                    }}
                  />
                  <TextField
                    label="End Date"
                    variant="outlined"
                    margin="normal"
                    type="date"
                    disabled={project.current ? true : false}
                    value={
                      project.project_end_date
                        ? project.project_end_date.substring(0, 10)
                        : project.project_end_date
                    }
                    onChange={(e) =>
                      handleCardChange(
                        index,
                        "project_end_date",
                        e.target.value,
                        "project"
                      )
                    }
                    InputLabelProps={{
                      shrink: true,
                    }}
                    InputProps={{
                      placeholder: project.project_end_date
                        ? undefined
                        : "MM/DD/YYYY",
                    }}
                  />
                  <div>
                    <label>
                      <input
                        type="checkbox"
                        checked={project.current}
                        onChange={(e) =>
                          handleCardChange(
                            index,
                            "current",
                            e.target.checked,
                            "project"
                          )
                        }
                      />
                      Currently Working on it
                    </label>
                  </div>
                </div>
                <TextField
                  label="Project Name"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={project.project_name}
                  onChange={(e) =>
                    handleCardChange(
                      index,
                      "project_name",
                      e.target.value,
                      "project"
                    )
                  }
                />
                <TextField
                  label="Role"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  value={project.project_role}
                  onChange={(e) =>
                    handleCardChange(
                      index,
                      "project_role",
                      e.target.value,
                      "project"
                    )
                  }
                />
                <h4 style={{ marginTop: "15px", marginBottom: "5px" }}>
                  Project Details
                </h4>
                <ReactQuill
                  theme="snow"
                  value={project.project_details}
                  onChange={(e) =>
                    handleCardChange(index, "project_details", e, "project")
                  }
                  style={{ height: "200px" }}
                />
                <TextField
                  label="Technologies"
                  variant="outlined"
                  fullWidth
                  margin="normal"
                  style={{ marginTop: "70px" }}
                  value={project.project_technologies}
                  onChange={(e) =>
                    handleCardChange(
                      index,
                      "project_technologies",
                      e.target.value,
                      "project"
                    )
                  }
                />
                <div>
                  <h4 style={{ marginTop: "15px", marginBottom: "5px" }}>
                    Roles & Responsibility
                  </h4>
                  <ReactQuill
                    theme="snow"
                    value={project.roles_responsibility}
                    onChange={(e) =>
                      handleCardChange(
                        index,
                        "roles_responsibility",
                        e,
                        "project"
                      )
                    }
                    style={{ height: "200px", marginBottom: "10px" }}
                  />
                </div>
                <Button
                  variant="contained"
                  color="error"
                  style={{ marginTop: "45px" }}
                  onClick={() => deleteCard(index, "project")}
                >
                  Delete Project
                </Button>

                {index !== projects.length - 1 && (
                  <Tooltip title="Move Downward">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => moveCard(index, "down", "project")}
                      style={{ marginTop: "45px", marginLeft: "5px" }}
                    >
                      <ArrowDownward />
                    </Button>
                  </Tooltip>
                )}
                {index !== 0 && (
                  <Tooltip title="Move Upward">
                    <Button
                      variant="contained"
                      color="primary"
                      onClick={() => moveCard(index, "up", "project")}
                      style={{ marginTop: "45px", marginLeft: "5px" }}
                    >
                      <ArrowUpward />
                    </Button>
                  </Tooltip>
                )}
              </CardContent>
            </Card>
          ))}

          <div
            className="project-section"
            style={{ display: "flex", alignItems: "center" }}
          >
            <FormControl component="fieldset">
              <div style={{ display: "flex" }}>
                <label htmlFor="Contractor">Contractor</label>
                <RadioGroup
                  aria-label="contractor"
                  name="contractor"
                  value={isContractor}
                  onChange={(event) => setIsContractor(event.target.value)}
                  style={{ marginRight: "30px" }}
                >
                  <div
                    style={{
                      display: "flex",
                      marginLeft: "40px",
                    }}
                  >
                    <FormControlLabel
                      value="Yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="No"
                      control={<Radio />}
                      label="No"
                    />
                  </div>
                </RadioGroup>
                <FormGroup>
                  <Box mt={2}>
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={addToFavorites}
                          onChange={(event) =>
                            setAddToFavorites(event.target.checked)
                          }
                        />
                      }
                      label="Add to favorites"
                    />
                  </Box>
                </FormGroup>
              </div>
            </FormControl>
          </div>

          {/* Submit Button */}
          <Button
            className="submit-button"
            variant="contained"
            color="primary"
            style={{ marginTop: "20px" }}
            onClick={handleSave}
          >
            Save
          </Button>
        </form>
      </div>
      <ReactModal
        for={getModalType()}
        open={
          openModal.aboutMe ||
          openModal.experience ||
          openModal.technicalSkills ||
          openModal.project
        }
        handleCloseModal={handleCloseModal}
        content={
          <ReactTable
            columns={getModalTableColumns()}
            data={getModalTableData()}
            count={getModalTableDataTotlCount()}
            title={getModalTitle()}
            type={getModalType()}
            handleCloseModal={handleCloseModal}
            handlePageChange={handlePageChange}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            selectedCardIndex={selectedCardIndex}
            search={handleSearch()}
            handleSearchInput={handleSearchInput}
            handleTypeChange={handleTypeChange}
          />
        }
      />
      <Snackbar
        className="profile-toaster"
        anchorOrigin={{ vertical, horizontal }}
        open={profileSaved}
        autoHideDuration={5000}
        onClose={handleClose}
        key={vertical + horizontal}
        message={`Profile ${
          localStorage.getItem("newUser") ? "Saved" : "Updated"
        } Successfully`}
      ></Snackbar>
    </div>
  );
};

export default AddResume;
