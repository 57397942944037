import { callGetRestApis } from "../../Services/Api";
import Config from "../../utils/Config";

export const getExistingAboutMe = async (queryParams, accessToken) => {
  const result = await callGetRestApis(
    Config.EXITINGABOUTME + queryParams,
    accessToken
  );
  return result;
};

export const getExistingExperience = async (queryParams, accessToken) => {
  const result = await callGetRestApis(
    Config.EXITINGEXPERIENCE + queryParams,
    accessToken
  );
  return result;
};

export const getExistingTechnicalSkills = async (queryParams, accessToken) => {
  const result = await callGetRestApis(
    Config.EXITINGTECHNICALSKILLS + queryParams,
    accessToken
  );
  return result;
};

export const getExistingProjects = async (queryParams, accessToken) => {
  const result = await callGetRestApis(
    Config.EXITINGPROJECT + queryParams,
    accessToken
  );
  return result;
};
