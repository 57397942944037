import Swal from "sweetalert2";
import { callGetRestApis } from "../Services/Api";
import Config from "./Config";

export const validateEmpty = (name, value, isCannot) => {
  if (!value)
    return {
      valid: false,
      errMsg: isCannot
        ? `${name} cannot be empty.`
        : `Please enter the ${name}`,
    };
  return { valid: true, errMsg: "" };
};

export function getAccessToken() {
  return localStorage.getItem("accessToken");
}

export function setAccessToken(token) {
  return localStorage.setItem("accessToken", token);
}

export const handleLogout = async () => {
  let token = getAccessToken();
  await callGetRestApis(Config.LOGOUT, token)
    .then((res) => {
      if (res?.status === 200) {
        localStorage.clear();
        window.location.href = "/";
      }
    })
    .catch((err) => {
      Swal.fire({
        title: "Oops...",
        text: "Something went wrong!",
        icon: "error",
      });
    });
};
